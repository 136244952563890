import React, {useContext} from "react";
import sum from "lodash.sum";

import Tacho from "../chart/Tacho";
import DataContext from "../context/DataContext";
import {KPI_PARTS, KPI_PARTS_PLAN, KPI_PASSES, KPI_PASSES_PLAN, KPI_WAGES, KPI_WAGES_PLAN} from "../../constant/kpi";
import LocalizationContext from "../context/LocalizationContext";
import FormatPercent from "../shared/FormatPercent";
import MonthPercentChart, {MonthPercentChartData} from "../chart/MonthPercentChart";
import bg from "../../asset/cockpit2.jpg";
import "./Cockpit.scss";

const Cockpit = () => {
    const {t} = useContext(LocalizationContext);
    const {months} = useContext(DataContext);

    const {
        filteredDatasets, filteredDatasetsAllMonths, filteredDatasetsPrev,
        filterLocation, filterMonth, filterYear
    } = useContext(DataContext);

    // parts
    const pDatasets = filteredDatasets.filter(d => d.kpi === KPI_PARTS)
    const pDatasetsPlan = filteredDatasets.filter(d => d.kpi === KPI_PARTS_PLAN)
    const pDatasetsPrev = filteredDatasetsPrev.filter(d => d.kpi === KPI_PARTS)
    const pTotal = sum(pDatasets.map(d => d.value));
    const pTotalPlan = sum(pDatasetsPlan.map(d => d.value));
    const pTotalPrev = sum(pDatasetsPrev.map(d => d.value));

    // wages
    const wDatasets = filteredDatasets.filter(d => d.kpi === KPI_WAGES)
    const wDatasetsPlan = filteredDatasets.filter(d => d.kpi === KPI_WAGES_PLAN)
    const wDatasetsPrev = filteredDatasetsPrev.filter(d => d.kpi === KPI_WAGES)
    const wTotal = sum(wDatasets.map(d => d.value));
    const wTotalPlan = sum(wDatasetsPlan.map(d => d.value));
    const wTotalPrev = sum(wDatasetsPrev.map(d => d.value));

    // passes
    const passDatasets = filteredDatasets.filter(d => d.kpi === KPI_PASSES)
    const passDatasetsPlan = filteredDatasets.filter(d => d.kpi === KPI_PASSES_PLAN)
    const passTotal = sum(passDatasets.map(d => d.value));
    const passTotalPlan = sum(passDatasetsPlan.map(d => d.value));

    // all months
    const passDatasetsAllMonths = filteredDatasetsAllMonths.filter(d => d.kpi === KPI_PASSES)
    const passDatasetsPlanAllMonths = filteredDatasetsAllMonths.filter(d => d.kpi === KPI_PASSES_PLAN)
    const plotData: MonthPercentChartData = months.map(month => {
        const total = sum(passDatasetsAllMonths.filter(d => d.month === month).map(d => d.value));
        const totalPlan = sum(passDatasetsPlanAllMonths.filter(d => d.month === month).map(d => d.value));
        return {
            x: month,
            y: totalPlan > 0 ? (total / totalPlan) * 100 : 0
        }
    })

    return (
        <div className={"cockpit-container"}>
            <div className={"cockpit"} style={{backgroundImage: "url(" + bg + ")"}}>
                <div className={"parts"}>
                    <Tacho label={t("cockpit.parts")}
                           value={pTotal} valuePlan={pTotalPlan} valuePrev={pTotalPrev}/>
                </div>
                <div className={"center"}>
                    <div className={"location"}>
                        {filterLocation ? filterLocation : t("cockpit.allLocations")}
                    </div>
                    <div className={"load"}>
                        <div className={"load-value"}>
                            <FormatPercent value={passTotal} valueTarget={passTotalPlan}/>
                        </div>
                        {t("cockpit.load")}
                    </div>
                    <div className={"timeframe"}>
                        {filterMonth && String(filterMonth).padStart(2, '0') + "/"}{filterYear ? filterYear : (new Date().getFullYear())}
                    </div>
                    <div className={"load-chart"}>
                        <MonthPercentChart data={plotData} id={"load-months"}/>
                    </div>
                </div>
                <div className={"wages"}>
                    <Tacho label={t("cockpit.wages")}
                           value={wTotal} valuePlan={wTotalPlan} valuePrev={wTotalPrev}/>
                </div>
            </div>
        </div>
    )
}

export default Cockpit;