import {theme} from "antd";
import {Theme} from "../type/Theme";
import beresa from "../asset/beresa.svg";
import beresaBg from "../asset/beresaBg.jpg";

// https://ant.design/docs/react/customize-theme#api
const themeDefault: Theme = {
    themeConfig: {
        token: {
            colorPrimary: '#00a6e2',
            colorLink: '#00a6e2',
            colorBorder: '#999',
            borderRadius: 2,
            colorBgContainer: '#333',
            colorBgLayout: '#f4f4f4',
            fontSizeHeading1: 30,
            fontSizeHeading2: 24,
            fontSizeHeading3: 18,
        },
        components: {
            Layout: {
                headerBg: '#000000',
                headerColor: '#fff',
                headerHeight: 96,
                footerBg: '#000000'
            },
        },
        algorithm: theme.darkAlgorithm
    },
    sidebarMenuTheme: "dark",
    logo: beresa,
    logoHeight: "40%",
    homeBackground: beresaBg
}

export default themeDefault;