import React, {PureComponent, type ReactNode} from 'react';
import axios from "axios";
import uniq from "lodash.uniq";

import DataContext, {StateData} from "./DataContext";
import {Dataset} from "../../type/Dataset";

interface Props {
    children: ReactNode
}

interface State extends StateData {
    datasets: Dataset[],
}

const mapMockData = (data: string): Dataset[] => {
    const datasets: Dataset[] = [];

    const lines = data.split("\n")
    lines.forEach(line => {
        const cols = line.trim().split(";");
        datasets.push({
            day: parseInt(cols[0].substring(0, 2)),
            month: parseInt(cols[0].substring(3, 5)),
            year: parseInt(cols[0].substring(6, 10)),
            location: cols[1],
            awKind: cols[2],
            channel: cols[3],
            business: cols[4],
            status: cols[5],
            kpi: cols[6],
            value: parseFloat(cols[7])
        });
    })
    return datasets;
}

class DataProvider extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            datasets: [],
            filteredDatasets: [],
            filteredDatasetsAllMonths: [],
            filteredDatasetsPrev: [],
            loading: true,
            locations: [],
            years: [],
            months: [],
            businesses: [],
            channels: [],
            status: [],
            awKinds: []
        };
    }

    componentDidMount() {
        this._loadData();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.state.loading !== prevState.loading ||
            this.state.filterLocation !== prevState.filterLocation ||
            this.state.filterBusiness !== prevState.filterBusiness ||
            this.state.filterChannel !== prevState.filterChannel ||
            this.state.filterStatus !== prevState.filterStatus ||
            this.state.filterMonth !== prevState.filterMonth ||
            this.state.filterYear !== prevState.filterYear) {
            this._recomputeFilteredData();
        }
    }

    private _recomputeFilteredData() {
        const {
            filterLocation, filterBusiness, filterChannel,
            filterStatus, filterMonth, filterYear
        } = this.state;

        const filteredData = this.state.datasets.filter(d => {
            if (filterLocation && filterLocation !== d.location) return false;
            if (filterBusiness && filterBusiness !== d.business) return false;
            if (filterChannel && filterChannel !== d.channel) return false;
            if (filterStatus && filterStatus !== d.status) return false;
            return true;
        });

        const activeYear = filterYear ? filterYear : (new Date().getFullYear());
        const dataAllMonths = filteredData.filter(d => activeYear === d.year);
        const dataCurrent = dataAllMonths.filter(d => !filterMonth || filterMonth === d.month);
        const dataPrev = filteredData.filter(d => (activeYear - 1) === d.year && (!filterMonth || filterMonth === d.month));

        this.setState({
            filteredDatasetsAllMonths: dataAllMonths,
            filteredDatasets: dataCurrent,
            filteredDatasetsPrev: dataPrev
        });
    }

    private _loadData = () => {
        axios.get((process.env.REACT_APP_BACKEND_URL ?? "") + "/data?size=10000")
            .then(async response => {
                const datasets: Dataset[] = response.data._embedded.data;
                while (response.data._links.next) {
                    response = await axios.get(response.data._links.next.href)
                    datasets.push(...response.data._embedded.data)
                }
                this.setState({
                    loading: false,
                    datasets: datasets,
                    filteredDatasets: datasets,

                    locations: uniq(datasets.map(d => d.location)).sort(),
                    years: uniq(datasets.map(d => d.year)).sort((a, b) => a - b),
                    months: uniq(datasets.map(d => d.month)).sort((a, b) => a - b),
                    businesses: uniq(datasets.map(d => d.business)).sort(),
                    channels: uniq(datasets.map(d => d.channel)).sort(),
                    status: uniq(datasets.map(d => d.status)).sort(),
                    awKinds: uniq(datasets.map(d => d.awKind)).sort(),

                    filterLocation: undefined,
                    filterYear: undefined,
                    filterMonth: undefined,
                    filterBusiness: undefined,
                    filterChannel: undefined,
                    filterStatus: undefined
                })
            })
    }

    updateLocation = (value?: string) => {
        this.setState({filterLocation: value})
    }

    updateYear = (value?: number) => {
        this.setState({filterYear: value})
    }

    updateMonth = (value?: number) => {
        this.setState({filterMonth: value})
    }

    updateBusiness = (value?: string) => {
        this.setState({filterBusiness: value})
    }

    updateChannel = (value?: string) => {
        this.setState({filterChannel: value})
    }

    updateStatus = (value?: string) => {
        this.setState({filterStatus: value})
    }

    render() {
        return (
            <DataContext.Provider value={{
                ...this.state,
                updateLocation: this.updateLocation,
                updateYear: this.updateYear,
                updateMonth: this.updateMonth,
                updateBusiness: this.updateBusiness,
                updateChannel: this.updateChannel,
                updateStatus: this.updateStatus,
            }}>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}

export default DataProvider;