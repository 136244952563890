import React, {PureComponent} from "react";

import "./ResponsiveText.scss";

const initWidth = 1000;
const initHeight = 100;

interface Props {
    text: string,
    color?: string
}

class ResponsiveText extends PureComponent<Props> {

    componentDidMount() {
        this.computeSize()
    }

    componentDidUpdate() {
        this.computeSize()
    }

    computeSize = () => {
        if (this.svg.current) {
            const bbox = this.svg.current.getBBox();
            this.svg.current.setAttribute("viewBox",
                "0 0 " + (bbox.x + bbox.width) + " " + (bbox.y + bbox.height));
        }
    }

    private svg = React.createRef<SVGSVGElement>()
    private text = React.createRef<SVGTextElement>()

    render() {
        const {text, color} = this.props;
        return (

            <svg viewBox={"0 0 " + initWidth + " " + initHeight} className={"responsive-text"}
                 ref={this.svg} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <text x={0} y={0} dominantBaseline={"hanging"} fill={color} ref={this.text}>
                    {text}
                </text>
            </svg>
        )
    }
}

export default ResponsiveText;