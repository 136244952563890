import {ReactNode, useContext} from "react";

import LocalizationContext from "../context/LocalizationContext";
import FormatNumber from "../shared/FormatNumber";
import FormatPercent from "../shared/FormatPercent";
import "./Tacho.scss";

interface Props {
    label: ReactNode,
    value: number,
    valuePlan: number,
    valuePrev: number
}

const Tacho = ({label, value, valuePrev, valuePlan}: Props) => {
    const {t} = useContext(LocalizationContext);

    const scale = ["-20", "-15", "-10", "-5", "0", "5", "10", "15", "20"];
    const percentage = valuePrev > 0 ? ((value - valuePrev) / valuePrev) * 100 : 0;
    const degree = 6 * Math.min(percentage, 20); // 6 deg = 1%

    return (
        <div className={"tachometer"}>
            <div className="ii">
                {scale.map((value, i) => (
                    <>
                        <div><b><span className={"num_" + (i + 1)}>{value}</span></b></div>
                        <div><b></b></div>
                    </>
                ))}
            </div>
            <div className="line" style={{transform: "rotate(" + degree + "deg)"}}></div>
            <div className="pin">
                <div className="inner"></div>
                <div className={"pin-label"}>{t("cockpit.needle")}</div>
            </div>
            <div className={"label"}>
                {label}
            </div>
            <div className={"total"}>
                <FormatNumber number={value} unit={"€"}/>
            </div>
            <div className={"goal"}>
                {t("cockpit.goal")}<br/>
                <FormatPercent value={value} valueTarget={valuePlan}/>
            </div>
        </div>
    )
}

export default Tacho;