import {ROUTE_AW, ROUTE_BRANCH, ROUTE_COCKPIT, ROUTE_DEEP_DIVE, ROUTE_HOME, ROUTE_ORDER} from "./route";

const localeDE = {
    route: {
        [ROUTE_HOME]: "Service Management Dashboard",
        [ROUTE_COCKPIT]: "Management Cockpit",
        [ROUTE_DEEP_DIVE]: "Management Deep-Dive",
        [ROUTE_BRANCH]: "Details Standort",
        [ROUTE_ORDER]: "Details offene Aufträge",
        [ROUTE_AW]: "Details AW"
    },
    filter: {
        location: "Standort",
        year: "Jahr",
        month: "Monat",
        business: "Teile/Lohn",
        channel: "Werkstatt/Theke",
        status: "Status",
    },
    deepDive: {
        total: "Umsatz Gesamt",
        parts: "Umsatz Teile",
        wages: "Umsatz Lohn",
        passes: "Fahrzeugdurchläufe"
    },
    unit: {
        million: "MIO",
        vsPrev: "vs VJ.",
        vsPlan: "vs Plan",
        aw: "AW",
        is: "IST",
        plan: "PLAN"
    },
    cockpit: {
        parts: "Teile",
        wages: "Lohn",
        needle: "vs. VJ%",
        goal: "Zielerreichung",
        load: "Auslastung",
        allLocations: "Alle Standorte"
    }
}

export default localeDE;