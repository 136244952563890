import React, {ReactNode, useContext} from "react";
import {ConfigProvider as AntConfigProvider} from 'antd';
import ThemeProvider from "../context/ThemeProvider";
import {Theme as ThemeT} from "../../type/Theme";
import themeBeresa from "../../theme/themeBeresa";
import LocalizationContext from "../context/LocalizationContext";

interface Props {
    children: ReactNode
}

const getTheme = () => {
    const themeName = process.env.REACT_APP_THEME;
    switch (themeName) {
        default:
            return themeBeresa;
    }
}

const mapCssVariables = (theme: ThemeT) => {
    const mappings: { [key: string]: any } = {
        '--primary': theme.themeConfig.token?.colorPrimary,
        '--border': theme.themeConfig.token?.colorBorder,
        '--sidebar': theme.themeConfig.token?.colorBgContainer
    }

    Object.keys(mappings).forEach(property => {
        document.documentElement.style.setProperty(property, mappings[property] ?? null);
    })
}

const Theme = ({children}: Props) => {
    const {locale} = useContext(LocalizationContext);
    const theme = getTheme();
    mapCssVariables(theme);

    return (
        <ThemeProvider themeContext={theme}>
            <AntConfigProvider locale={locale} theme={theme.themeConfig}>
                {children}
            </AntConfigProvider>
        </ThemeProvider>
    )
}

export default Theme;