import React, {useContext} from "react";
import LocalizationContext from "../context/LocalizationContext";

interface Props {
    valueOld: number,
    valueNew: number
}

const FormatPercentDelta = ({valueOld, valueNew}: Props) => {
    const {lang} = useContext(LocalizationContext);

    if (valueOld === 0) return <>-</>;

    const percent = ((valueNew - valueOld) / valueOld) * 100;
    return <>{percent >= 0 && "+"}{percent.toLocaleString(lang, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    })} %</>
}

export default FormatPercentDelta;