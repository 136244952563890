import React, {useContext} from "react";
import LocalizationContext from "../context/LocalizationContext";

interface Props {
    value: number,
    valueTarget: number
}

const FormatPercent = ({value, valueTarget}: Props) => {
    const {lang} = useContext(LocalizationContext);

    if (valueTarget <= 0) return <>-</>;

    const percent = (value / valueTarget) * 100;
    return <>{percent.toLocaleString(lang, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    })}%</>
}

export default FormatPercent;