import React from 'react';
import {Dataset} from '../../type/Dataset';

export interface StateData {
    loading: boolean,
    filteredDatasets: Dataset[],
    filteredDatasetsAllMonths: Dataset[],
    filteredDatasetsPrev: Dataset[],

    locations: string[],
    years: number[],
    months: number[],
    businesses: string[],
    channels: string[],
    status: string[],
    awKinds: string[],

    filterLocation?: string,
    filterYear?: number,
    filterMonth?: number,
    filterBusiness?: string,
    filterChannel?: string,
    filterStatus?: string
}

interface DataContext extends StateData {
    updateLocation: (value?: string) => void,
    updateYear: (value?: number) => void,
    updateMonth: (value?: number) => void,
    updateBusiness: (value?: string) => void,
    updateChannel: (value?: string) => void,
    updateStatus: (value?: string) => void,
}

export default React.createContext<DataContext>({
    filteredDatasets: [],
    filteredDatasetsAllMonths: [],
    filteredDatasetsPrev: [],
    loading: false,

    locations: [],
    years: [],
    months: [],
    businesses: [],
    channels: [],
    status: [],
    awKinds: [],

    updateLocation: () => {},
    updateYear: () => {},
    updateMonth: () => {},
    updateBusiness: () => {},
    updateChannel: () => {},
    updateStatus: () => {}
});