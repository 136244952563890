import {useContext} from "react";

import LocalizationContext from "../context/LocalizationContext";
import DeepDiveCol from "./DeepDiveCol";
import {KPI_PARTS, KPI_PARTS_PLAN, KPI_PASSES, KPI_PASSES_PLAN, KPI_WAGES, KPI_WAGES_PLAN} from "../../constant/kpi";
import "./DeepDive.scss"

const DeepDive = () => {
    const {t} = useContext(LocalizationContext);
    return (
        <div className={"ssys-deep-dive"}>
            <DeepDiveCol label={t("deepDive.total")} roundMillions={true} id={"total"}
                         kpiPlan={[KPI_PARTS_PLAN, KPI_WAGES_PLAN]}
                         kpiResult={[KPI_PARTS, KPI_WAGES]}/>
            <DeepDiveCol label={t("deepDive.parts")} roundMillions={true} id={"parts"}
                         kpiPlan={[KPI_PARTS_PLAN]} kpiResult={[KPI_PARTS]}/>
            <DeepDiveCol label={t("deepDive.wages")} roundMillions={true} id={"wages"}
                         kpiPlan={[KPI_WAGES_PLAN]} kpiResult={[KPI_WAGES]}/>
            <DeepDiveCol label={t("deepDive.passes")} id={"passes"}
                         kpiPlan={[KPI_PASSES_PLAN]} kpiResult={[KPI_PASSES]}/>
        </div>
    )
}

export default DeepDive;