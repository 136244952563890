import {notification} from "antd";
import {useEffect} from "react";
import {NotificationData, removeNotificationErrorListener, setNotificationErrorListener} from "../../util/event";

const MessageHelper = () => {
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        const onError = (e: CustomEvent<NotificationData>) => {
            api.error({
                message: e.detail.title,
                description: e.detail.message
            });
        }

        setNotificationErrorListener(onError);
        return () => removeNotificationErrorListener(onError);
    }, [api]);

    return (
        <>
            {contextHolder}
        </>
    )
}

export default MessageHelper;