import Cockpit from "../component/view/Cockpit";
import DeepDive from "../component/view/DeepDive";
import Branch from "../component/view/Branch";
import Order from "../component/view/Order";
import Aw from "../component/view/Aw";

export const ROUTE_HOME = "/";
export const ROUTE_COCKPIT = "/cockpit";
export const ROUTE_DEEP_DIVE = "/deep-dive";
export const ROUTE_BRANCH = "/standort";
export const ROUTE_ORDER = "/auftraege";
export const ROUTE_AW = "/aw";

export const sidebarRoutes = [{
    route: ROUTE_COCKPIT,
    component: Cockpit
}, {
    route: ROUTE_DEEP_DIVE,
    component: DeepDive
}, {
    route: ROUTE_BRANCH,
    component: Branch
}, {
    route: ROUTE_ORDER,
    component: Order
}, {
    route: ROUTE_AW,
    component: Aw
}]