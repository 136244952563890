import React, {ReactNode, useContext} from "react";
import sum from "lodash.sum";

import DataContext from "../context/DataContext";
import LocalizationContext from "../context/LocalizationContext";
import {Dataset} from "../../type/Dataset";
import FormatNumber from "../shared/FormatNumber";
import FormatPercentDelta from "../shared/FormatPercentDelta";
import MonthChart, {MonthChartData} from "../chart/MonthChart";
import "./DeepDiveCol.scss";

interface AwProps {
    value: string,
    datasets: Dataset[],
    datasetsPlan: Dataset[],
    datasetsPrev: Dataset[],
    roundMillions?: boolean
}

const getComparisonClass = (a: number, b: number) => {
    let c = "comparison";

    if (b > a) c += " positive";
    if (b < a) c += " negative";

    return c;
}

const AwRow = ({value, roundMillions, datasets, datasetsPrev, datasetsPlan}: AwProps) => {
    const d = datasets.filter(d => d.awKind === value);
    const dPlan = datasetsPlan.filter(d => d.awKind === value)
    const dPrev = datasetsPrev.filter(d => d.awKind === value)

    const total = sum(d.map(d => d.value));
    const totalPlan = sum(dPlan.map(d => d.value));
    const totalPrev = sum(dPrev.map(d => d.value));

    return (
        <>
            <div className={"plain"}>{value}</div>
            <div className={"plain"}><FormatNumber number={total} roundMi={roundMillions}/></div>
            <div className={getComparisonClass(totalPrev, total)}>
                <FormatPercentDelta valueOld={totalPrev} valueNew={total}/>
            </div>
            <div className={getComparisonClass(totalPlan, total)}>
                <FormatPercentDelta valueOld={totalPlan} valueNew={total}/>
            </div>
        </>
    )
}

interface Props {
    id: string,
    label: ReactNode,
    kpiPlan: string[],
    kpiResult: string[],
    roundMillions?: boolean
}

const DeepDiveCol = ({id, label, kpiPlan, kpiResult, roundMillions}: Props) => {
    const {t} = useContext(LocalizationContext);
    const {filteredDatasets, filteredDatasetsAllMonths, filteredDatasetsPrev, awKinds, months} = useContext(DataContext);

    const datasets = filteredDatasets.filter(d => kpiResult.includes(d.kpi))
    const datasetsPlan = filteredDatasets.filter(d => kpiPlan.includes(d.kpi))
    const datasetsPrev = filteredDatasetsPrev.filter(d => kpiResult.includes(d.kpi))

    const total = sum(datasets.map(d => d.value));
    const totalPlan = sum(datasetsPlan.map(d => d.value));
    const totalPrev = sum(datasetsPrev.map(d => d.value));

    // month plots
    const datasetsAllMonths = filteredDatasetsAllMonths.filter(d => kpiResult.includes(d.kpi))
    const datasetsPlanAllMonths = filteredDatasetsAllMonths.filter(d => kpiPlan.includes(d.kpi))

    const plotData: MonthChartData = months.map(month => {
        return {
            x: month,
            yIs: sum(datasetsAllMonths.filter(d => d.month === month).map(d => d.value)),
            yPlan: sum(datasetsPlanAllMonths.filter(d => d.month === month).map(d => d.value))
        }
    })

    return (
        <div className={"ssys-deep-dive-col"}>
            <div className={"header"}>
                {label}
            </div>
            <div className={"total"}>
                <FormatNumber number={total} roundMi={roundMillions}/>
            </div>
            <div className={"compare"}>
                <div className={getComparisonClass(totalPrev, total)}>
                    <div className={"label"}>{t("unit.vsPrev")}</div>
                    <div className={"value"}><FormatPercentDelta valueOld={totalPrev} valueNew={total}/></div>
                </div>
                <div className={getComparisonClass(totalPlan, total)}>
                    <div className={"label"}>{t("unit.vsPlan")}</div>
                    <div className={"value"}><FormatPercentDelta valueOld={totalPlan} valueNew={total}/></div>
                </div>
            </div>
            <MonthChart data={plotData} id={id} showPlan={true} showAxis={true}/>
            <div className={"aw-grid"}>
                <div className={"head"}>{t("unit.aw")}</div>
                <div className={"head"}>{t("unit.is")}</div>
                <div className={"head"}>{t("unit.vsPrev")}</div>
                <div className={"head"}>{t("unit.vsPlan")}</div>
                {awKinds.map(awKind => (
                    <AwRow key={awKind} value={awKind} roundMillions={roundMillions}
                           datasets={datasets} datasetsPlan={datasetsPlan} datasetsPrev={datasetsPrev}/>
                ))}
            </div>
        </div>
    )
}

export default DeepDiveCol;