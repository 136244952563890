import {ReactElement} from "react";

const EVENT_NOTIFY_ERROR = "notifyError";

export interface NotificationData {
    title: ReactElement | string,
    message: ReactElement | string
}

export const setNotificationErrorListener = (handler: (e: CustomEvent<NotificationData>) => void) => {
    document.addEventListener(EVENT_NOTIFY_ERROR, handler as EventListener);
};

export const removeNotificationErrorListener = (handler: (e: CustomEvent<NotificationData>) => void) => {
    document.removeEventListener(EVENT_NOTIFY_ERROR, handler as EventListener);
};

export const notifyError = (data: NotificationData) => {
    document.dispatchEvent(new CustomEvent(EVENT_NOTIFY_ERROR, {detail: data}));
}
