import React, {useContext} from "react";
import {Link} from "react-router-dom";

import {sidebarRoutes} from "../../constant/route";
import LocalizationContext from "../context/LocalizationContext";
import "./Home.scss"

const Home = () => {
    const {t} = useContext(LocalizationContext);
    return (
        <div className={"ssys-home"}>
            {sidebarRoutes.map(routeConfig => (
                <Link to={routeConfig.route} className={"home-tile"} key={routeConfig.route}>
                    {t('route.' + routeConfig.route)}
                </Link>
            ))}
        </div>
    )
}

export default Home;