import React, {useContext} from "react";
import LocalizationContext from "../context/LocalizationContext";

interface Props {
    number: number,
    roundMi?: boolean,
    unit?: string
}

const FormatNumber = ({number, unit, roundMi = false}: Props) => {
    const {lang, t} = useContext(LocalizationContext);

    return roundMi
        ? <>{(number / 1000000).toLocaleString(lang, {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
        })} {t("unit.million")} {unit}</>
        : <>{number.toLocaleString(lang, {useGrouping: true})} {unit}</>
}

export default FormatNumber;