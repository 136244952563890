import {theme} from 'antd';
import React, {useContext} from 'react';
import Chart from 'react-apexcharts'

import LocalizationContext from "../context/LocalizationContext";
import {getLocalizedMonthName} from "../../util/label";

export type MonthChartData = {
    x: number,
    yIs: number,
    yPlan: number
}[]

interface Props {
    data: MonthChartData,
    id: string,
    showPlan?: boolean,
    showAxis?: boolean
}

const MonthChart = ({data, id, showPlan = false, showAxis = false}: Props) => {
    const {lang, tStr} = useContext(LocalizationContext);
    const {token: {colorPrimary}} = theme.useToken();

    const options = {
        chart: {
            id: id,
            toolbar: {show: false}
        },
        plotOptions: {
            bar: {
                columnWidth: "50%"
            }
        },
        stroke: {
            width: 2,
        },
        xaxis: {
            categories: data.map(d => getLocalizedMonthName(d.x, lang)),
            labels: {
                show: showAxis
            }
        },
        markers: {
            size: 2,
            strokeWidth: 0,
            fillOpacity: 100,
            strokeOpacity: 100,
            hover: {
                size: 3,
            }
        },
        yaxis: {show: false}
    }

    const series = [{
        name: tStr("unit.is"),
        type: "column",
        data: data.map(d => d.yIs),
        color: colorPrimary
    }]

    if (showPlan) {
        series.push({
            name: tStr("unit.plan"),
            type: "line",
            data: data.map(d => d.yPlan),
            color: "#000"
        });
    }
    return (
        <Chart options={options} series={series} type="line" width={"100%"} height={200}/>
    );
}

export default MonthChart;