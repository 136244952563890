import React, {PureComponent, ReactNode} from 'react';

import ThemeContext, {ThemeContextType} from "./ThemeContext";

interface Props {
    themeContext: ThemeContextType,
    children: ReactNode
}

class ThemeProvider extends PureComponent<Props> {
    render() {
        return (
            <ThemeContext.Provider value={this.props.themeContext}>
                {this.props.children}
            </ThemeContext.Provider>
        )
    }
}

export default ThemeProvider;