import React from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";

import LocalizationProvider from "./context/LocalizationProvider";
import MessageHelper from "./context/MessageHelper";
import DataProvider from "./context/DataProvider";
import {ROUTE_HOME, sidebarRoutes} from "../constant/route";
import Home from "./view/Home";
import Layout from "./layout/Layout";
import Theme from "./layout/Theme";
import "./App.scss";

const App = () => {
    return (
        <LocalizationProvider>
            <Theme>
                <MessageHelper/>
                <DataProvider>
                    <HashRouter>
                        <Layout>
                            <Routes>
                                <Route path={ROUTE_HOME} element={<Home/>}/>
                                {sidebarRoutes.map(route => {
                                    const Component = route.component;
                                    return (
                                        <Route path={route.route} key={route.route} element={<Component/>}/>
                                    )
                                })}
                            </Routes>
                        </Layout>
                    </HashRouter>
                </DataProvider>
            </Theme>
        </LocalizationProvider>
    );
}

export default App;
