import {theme} from 'antd';
import React, {useContext} from 'react';
import Chart from 'react-apexcharts'

import LocalizationContext from "../context/LocalizationContext";
import {getLocalizedMonthName} from "../../util/label";
import {ApexOptions} from "apexcharts";

export type MonthPercentChartData = {
    x: number,
    y: number
}[]

interface Props {
    data: MonthPercentChartData,
    id: string
}

const MonthPercentChart = ({data, id}: Props) => {
    const {lang, tStr} = useContext(LocalizationContext);
    const {token: {colorPrimary}} = theme.useToken();

    const options: ApexOptions = {
        chart: {
            id: id,
            toolbar: {show: false}
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                columnWidth: "50%"
            }
        },
        stroke: {
            width: 2,
        },
        xaxis: {
            categories: data.map(d => getLocalizedMonthName(d.x, lang)),
            labels: {
                show: false
            }
        },
        yaxis: {
            stepSize: 100,
            decimalsInFloat: 0,
            min: 0,
            max: 100,
            opposite: true,
            labels: {
                style: {
                    colors: "#fff"
                },
                formatter: (y: number) =>  y + "%"
            }
        }
    }

    const series = [{
        data: data.map(d => d.y),
        color: colorPrimary,
        name: tStr("cockpit.load")
    }]

    return (
        <Chart options={options} series={series} type="bar" width={"100%"} height={150}/>
    );
}

export default MonthPercentChart;