import React, {useContext} from 'react'
import {Select} from "antd";

import DataContext from "../context/DataContext";
import LocalizationContext from "../context/LocalizationContext";
import {getLocalizedMonthName} from "../../util/label";
import "./Filters.scss"

const LocationFilter = () => {
    const {locations, filterLocation, updateLocation} = useContext(DataContext);
    const {t} = useContext(LocalizationContext)
    return (
        <Select value={filterLocation} onChange={updateLocation} style={{width: "100%"}}
                allowClear={true} placeholder={t("filter.location")} options={locations.map(x => {
            return {value: x, label: x}
        })}/>
    )
}
const YearFilter = () => {
    const {years, filterYear, updateYear} = useContext(DataContext);
    const {t} = useContext(LocalizationContext)
    return (
        <Select value={filterYear} onChange={updateYear} style={{width: "100%"}}
                allowClear={true} placeholder={t("filter.year")} options={years.map(x => {
            return {value: x, label: x}
        })}/>
    )
}
const MonthFilter = () => {
    const {months, filterMonth, updateMonth} = useContext(DataContext);
    const {t, lang} = useContext(LocalizationContext)
    return (
        <Select value={filterMonth} onChange={updateMonth} style={{width: "100%"}}
                allowClear={true} placeholder={t("filter.month")} options={months.map(x => {
            return {value: x, label: getLocalizedMonthName(x, lang, true)}
        })}/>
    )
}
const BusinessFilter = () => {
    const {businesses, filterBusiness, updateBusiness} = useContext(DataContext);
    const {t} = useContext(LocalizationContext)
    return (
        <Select value={filterBusiness} onChange={updateBusiness} style={{width: "100%"}}
                allowClear={true} placeholder={t("filter.business")} options={businesses.map(x => {
            return {value: x, label: x}
        })}/>
    )
}
const ChannelFilter = () => {
    const {channels, filterChannel, updateChannel} = useContext(DataContext);
    const {t} = useContext(LocalizationContext)
    return (
        <Select value={filterChannel} onChange={updateChannel} style={{width: "100%"}}
                allowClear={true} placeholder={t("filter.channel")} options={channels.map(x => {
            return {value: x, label: x}
        })}/>
    )
}
const StatusFilter = () => {
    const {status, filterStatus, updateStatus} = useContext(DataContext);
    const {t} = useContext(LocalizationContext)
    return (
        <Select value={filterStatus} onChange={updateStatus} style={{width: "100%"}}
                allowClear={true} placeholder={t("filter.status")} options={status.map(x => {
            return {value: x, label: x}
        })}/>
    )
}

const Filters = () => {

    return (
        <div className={"ssys-filters"}>
            <LocationFilter/>
            <YearFilter/>
            <MonthFilter/>
            <ChannelFilter/>
            <StatusFilter/>
        </div>
    )
}

export default Filters;