import React, {ReactElement} from 'react';
import type {Locale} from "antd/lib/locale";
import localeDE from 'antd/lib/locale/de_DE';

interface LocalizationContext {
    t: (x: string, values?: Record<string, any>) => ReactElement,
    tStr: (x: string, values?: Record<string, any>) => string,
    locale: Locale,
    lang: string
}

export default React.createContext<LocalizationContext>({
    t: (x: string): ReactElement => <>{x}</>,
    tStr: (x: string): string => x,
    locale: localeDE,
    lang: "de-DE"
});