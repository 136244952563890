import React, {ReactNode, useContext} from "react";
import {Layout as AntdLayout, Menu, Spin, theme} from 'antd';
import {Link, useLocation} from "react-router-dom";

import LocalizationContext from "../context/LocalizationContext";
import {ROUTE_HOME, sidebarRoutes} from "../../constant/route";
import ThemeContext from "../context/ThemeContext";
import ResponsiveText from "../shared/ResponsiveText";
import Filters from "./Filters";
import DataContext from "../context/DataContext";
import logoStar from "../../asset/star_cooperation.svg";
import logoStackIt from "../../asset/stackit.svg";
import logoAutoDm from "../../asset/autodm_white.png";
import "./Layout.scss";

interface Props {
    children: ReactNode
}

const Layout = ({children}: Props) => {
    const {t, tStr} = useContext(LocalizationContext);
    const {loading} = useContext(DataContext);

    const location = useLocation();
    const {logo, logoHeight, sidebarMenuTheme, homeBackground} = useContext(ThemeContext);
    const {token: {Layout, colorBgContainer}} = theme.useToken();

    const navItems = sidebarRoutes.map(routeConfig => {
        return {
            key: routeConfig.route,
            label: <Link to={routeConfig.route}>{t('route.' + routeConfig.route)}</Link>
        }
    })

    const goHome = () => {
        window.location.hash = ROUTE_HOME;
    }

    const isHomepage = location.pathname === ROUTE_HOME;

    return (
        <AntdLayout className={"ssys-layout-wrapper"}>
            <AntdLayout.Header className={"ssys-layout-header"}>
                {logo && <img src={logo} style={{height: logoHeight}} alt={""} onClick={goHome}/>}
                <div style={{height: logoHeight, marginLeft: "2rem"}}>
                    <ResponsiveText text={tStr('route.' + location.pathname)} color={Layout?.headerColor}/>
                </div>
            </AntdLayout.Header>
            <AntdLayout className={"ssys-layout-main"} style={isHomepage ? {
                backgroundImage: 'url("' + homeBackground + '")'
            } : {}}>
                {loading && !isHomepage
                    ? <Spin size={"large"} className={"ssys-loading"}/>
                    : <>
                        {!isHomepage &&
                            <AntdLayout.Sider style={{backgroundColor: colorBgContainer}}>
                                <div className={"ssys-layout-sidebar"}>
                                    <Menu
                                        style={{background: "transparent"}}
                                        mode="inline"
                                        theme={sidebarMenuTheme}
                                        inlineIndent={12}
                                        items={navItems}
                                        selectedKeys={[location.pathname]}
                                    />
                                    <Filters/>
                                </div>
                            </AntdLayout.Sider>}
                        <AntdLayout.Content style={{padding: "1.5rem", overflow: "auto"}}>
                            {children}
                        </AntdLayout.Content></>}
            </AntdLayout>
            <AntdLayout.Footer style={{padding: "1rem"}}>
                <div className={"ssys-footer"}>
                    <span>powered by:</span>
                    <img src={logoAutoDm} alt={"Auto DM"}/>
                    <img src={logoStar} alt={"Star Cooperation"}/>
                    <img src={logoStackIt} alt={"StackIt"}/>
                </div>
            </AntdLayout.Footer>
        </AntdLayout>
    )
}

export default Layout;