import React, {PureComponent, ReactElement, type ReactNode} from 'react';
import get from 'lodash.get';
import nl2br from 'react-nl2br';
import localeDE from "antd/lib/locale/de_DE";
import 'dayjs/locale/de';

import LocalizationContext from './LocalizationContext';
import locale_DE from '../../constant/locale_DE';

interface Props {
    children: ReactNode
}

interface State {
    source: object,
    lang: string
}

type Values = Record<string, any>;

class LocalizationProvider extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            source: locale_DE,
            lang: "de-DE"
        };
    }

    /**
     * @param key - example: "x.y.z" to match object hierarchy
     * @param values  - example: {n: 1} to set value {n} in localization string
     */
    tStr = (key: string, values: Values = {}): string => {
        // if no localization exists, print key instead
        let localizedString = get(this.state.source, key, key);

        if (typeof localizedString === 'object') {
            localizedString = '[object] ' + key;
        }

        Object.keys(values).forEach(key => {
            localizedString = localizedString.replace('{' + key + '}', values[key]);
        });

        return localizedString;
    }

    t = (key: string, values: Values = {}): ReactElement => {
        const localizedString = this.tStr(key, values);
        return localizedString.includes('\n') ? <>{nl2br(localizedString)}</> : <>{localizedString}</>;
    }

    render() {
        return (
            <div lang={this.state.lang}>
                <LocalizationContext.Provider value={{
                    t: this.t,
                    tStr: this.tStr,
                    locale: localeDE,
                    lang: this.state.lang
                }}>
                    {this.props.children}
                </LocalizationContext.Provider>
            </div>
        )
    }
}

export default LocalizationProvider;